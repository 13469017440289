@media only screen and (min-width: 450px) and (max-width: 767px) {
    .driving-licence-wrapper {
        width: 75% !important;
        margin: 0 auto;
    }
}

.driving-licence-wrapper {
    margin-bottom: 25px;
}

.price-comparison-container {
    margin: 20px -100px 0 -100px;
}

@media only screen and (max-width: 767px) {
    .price-comparison-container {
        margin: auto;
    }
}