@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap);
@media only screen and (max-width: 768px) {
    .quote-summary-title {
        font-size: 10px;
    }

    .quote-summary-text {
        font-size: 10px;
        font-weight: 700;
    }
}
.StripeElement {
  display: block;
  margin: 10px 0 32px 0;
  min-width: 30px;
  padding: 10px 14px;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border-radius: 0px;
  border: 2px solid #1f1f1f;
  background: white;
  color:#1f1f1f;
}

.StripeElement--invalid {
  border: 1px solid red;
}

@media only screen and (min-width: 450px) and (max-width: 767px) {
    .driving-licence-wrapper {
        width: 75% !important;
        margin: 0 auto;
    }
}

.driving-licence-wrapper {
    margin-bottom: 25px;
}

.price-comparison-container {
    margin: 20px -100px 0 -100px;
}

@media only screen and (max-width: 767px) {
    .price-comparison-container {
        margin: auto;
    }
}
.camera {
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 767px) and (orientation: portrait) {
  .camera {
    object-fit: initial;
  }
}

@media (max-width: 767px) and (orientation: landscape) {
  .camera {
    object-fit: initial;
  }
}
@media (min-width: 768px) and (max-width: 850px) and (orientation: landscape) {
  .camera {
    object-fit: initial;
  }
}

@media (min-width: 767px) and (max-width: 1024px) and (orientation: portrait) {
  .camera {
    object-fit: initial;
  }
}
@media (min-width: 850px) and (max-width: 1024px) and (orientation: landscape) {
  .camera {
    object-fit: initial;
  }
}
@font-face {
  font-family: BrandonText;
  src: url(/static/media/BrandonText-Regular.cc4e72bd.otf);
}

@font-face {
  font-family: BrandonText-Bold;
  src: url(/static/media/BrandonText-Bold.89fdab2e.otf);
}

@font-face {
  font-family: Charles-Wright;
  src: url(/static/media/CharlesWright-Bold.1064bef3.ttf);
  font-weight: bold;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  background-color: #efefef;
}

body {
  font-family: Poppins, Helvetica, Arial, sans-serif;
}

#app {
  height: 100%;
  min-width: 100%;
}

p,
label {
  line-height: 1.5em;
}
.grid-container {
  margin-left: 0;
}

.grid-parent {
  margin: 0;
  padding: 0;
}

.left-margin {
  margin-left: 10px;
}

input:focus {
  outline: none;
}

