@font-face {
  font-family: BrandonText;
  src: url(./fonts/BrandonText-Regular.otf);
}

@font-face {
  font-family: BrandonText-Bold;
  src: url(./fonts/BrandonText-Bold.otf);
}

@font-face {
  font-family: Charles-Wright;
  src: url(./fonts/CharlesWright-Bold.ttf);
  font-weight: bold;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  background-color: #efefef;
}

body {
  font-family: Poppins, Helvetica, Arial, sans-serif;
}

#app {
  height: 100%;
  min-width: 100%;
}

p,
label {
  line-height: 1.5em;
}
.grid-container {
  margin-left: 0;
}

.grid-parent {
  margin: 0;
  padding: 0;
}

.left-margin {
  margin-left: 10px;
}

input:focus {
  outline: none;
}
